import { CountUp } from 'countup.js';

document.addEventListener('scroll', function(e) {
  if (e.target !== document) { return; }

  const elements = document.querySelectorAll('section.sector-support .cards a.card span:not(.counted)');

  for (let elem of elements) {
    const bnds = elem.getBoundingClientRect();

    if ((bnds.top >= 0) && (bnds.left >= 0) &&
       (bnds.right <= (window.innerWidth || document.documentElement.clientWidth)) &&
       (bnds.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    ) {
      elem.classList.add('counted');

      const count = new CountUp(
        elem,
        elem.dataset.countup,
        { duration: 2.5, useEasing: true, useGrouping: true, separator: ',', decimal: '.' }
      );
      count.start();
    };
  }
});
